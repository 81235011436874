import {
  takeLatest, call, put, delay,
} from 'redux-saga/effects';
import { parseServerError, downloadFile } from 'utils/utils';
import { sortBy } from 'lodash';
import * as constants from './photos.constant';
import * as api from './Photos.api';
import * as action from './Photos.actions';
import { TIME_INTERVAL_FOR_ZIP_POLL } from './photos.constant';

export function* fetchOrderDetailsSaga({ payload }) {
  try {
    const response = yield call(api.fetchOrderDetailsApi, payload);
    const structures = sortBy(response.Structures, 'structureID');
    structures.forEach((structure, idx) => {
      const tempStructure = structure;
      tempStructure.structureName = idx === 0 ? 'Main Structure' : `Structure ${idx + 1}`;
      return tempStructure;
    });
    response.Structures = structures;
    yield put(action.fetchOrderDetailsSuccessAction(response));
  } catch (error) {
    yield put(action.fetchOrderDetailsFailureAction(parseServerError(error)));
  }
}

export function* fetchThumbnailsSaga({ payload }) {
  try {
    const response = yield call(api.getThumbnailsApi, payload);
    yield put(action.fetchThumbnailSuccessAction({ thumbnails: response, imageType: payload.imageType }));
  } catch (error) {
    yield put(action.fetchThumbnailFailureAction(parseServerError(error)));
  }
}

export function* fetchFullImageSaga({ payload }) {
  try {
    const response = yield call(api.fetchRoofImageApi, payload);
    yield put(action.fetchFullImageSuccessAction({ image: response, imageType: payload.imageType }));
  } catch (error) {
    yield put(action.fetchFullImageFailureAction(parseServerError(error)));
  }
}

export function* fetchRiskShotSaga({ payload }) {
  try {
    const response = yield call(api.getThumbnailsApi, payload);
    yield put(action.fetchRiskshotSuccessAction(response));
  } catch (error) {
    yield put(action.fetchRiskshotFailureAction(parseServerError(error)));
  }
}

export function* fetchPhotoAssetsSaga({ payload }) {
  try {
    const response = yield call(api.getPhotoAssets, payload);
    yield put(action.fetchPhotoAssetsSuccessAction({ requestType: payload.requestType, data: response }));
  } catch (error) {
    yield put(action.fetchPhotoAssetsFailureAction(parseServerError(error)));
  }
}

export function* initiateDownloadZipSaga({ payload }) {
  try {
    const response = yield call(api.createZip, payload);
    yield put(action.initiateZipDownloadSuccessAction(response));
  } catch (error) {
    yield put(action.initiateZipDownloadFailureAction(parseServerError(error)));
  }
}

export function* checkZipStatusSaga({ payload }) {
  while (true) {
    try {
      const response = yield call(api.checkZipStatusApi, payload);
      if (response.zipStatus === 'Complete') {
        yield put(action.checkZipStatusSuccessAction(response));
        // Download the zip file
        downloadFile(response.downloadUrl);
        break;
      }
      if (response.zipStatus === 'Failure') {
        yield put(action.checkZipStatusFailureAction(response));
        break;
      }
      // for inprogress status continue polling
      yield delay(TIME_INTERVAL_FOR_ZIP_POLL); // Wait for 3 mins sec
    } catch (error) {
      yield put(action.checkZipStatusFailureAction(parseServerError(error)));
      break;
    }
  }
}

export function* fetchAllTagsSaga({ payload }) {
  try {
    const response = yield call(api.getAllTagsFromS3Api, payload);
    yield put(action.fetchAllTagsFromS3SuccessAction(response));
  } catch (error) {
    yield put(action.fetchAllTagsFromS3FailureAction(parseServerError(error)));
  }
}

export function* fetchGroundThumbnailsSaga({ payload }) {
  try {
    const { groundImageTags } = payload;
    const groundThumbnails = [];
    for (let i = 0; i < groundImageTags.length; i += 1) {
      const response = yield call(api.getThumbnailsApi, {
        ...payload,
        groundImageTag: groundImageTags[i].tag_id,
        imageType: 'ground',
      });
      groundThumbnails.push({
        tag: groundImageTags[i].tag_id,
        data: response,
      });
    }
    yield put(action.fetchGroundImageThumbnailsSuccessAction(groundThumbnails));
  } catch (error) {
    yield put(action.fetchGroundImageThumbnailsFailureAction(parseServerError(error)));
  }
}

export function* fetchFullGalleryImageSaga({ payload }) {
  try {
    const response = yield call(api.fetchRoofImageApi, payload);
    yield put(action.fetchGalleryFullImageSuccessAction({ data: response.data }));
  } catch (error) {
    yield put(action.fetchGalleryFullImageFailureAction(parseServerError(error)));
  }
}

export default function* photosSaga() {
  yield takeLatest(constants.FETCH_ORDER_DETAILS, fetchOrderDetailsSaga);
  yield takeLatest(constants.FETCH_THUMBNAILS, fetchThumbnailsSaga);
  yield takeLatest(constants.FETCH_RISK_SHOT, fetchRiskShotSaga);
  yield takeLatest(constants.FETCH_PHOTO_ASSETS, fetchPhotoAssetsSaga);
  yield takeLatest(constants.FETCH_FULL_IMAGE, fetchFullImageSaga);
  yield takeLatest(constants.INITIATE_DOWNLOAD_ZIP, initiateDownloadZipSaga);
  yield takeLatest(constants.ZIP_STATUS, checkZipStatusSaga);
  yield takeLatest(constants.GET_ALL_TAGS_FROM_S3, fetchAllTagsSaga);
  yield takeLatest(constants.GET_GROUND_THUMBNAILS, fetchGroundThumbnailsSaga);
  yield takeLatest(constants.FETCH_FULL_GALLERY_IMAGE, fetchFullGalleryImageSaga);
}
