const prefix = 'entitleUserReducer';

export const FETCH_USER_ENTITLEMENTS = `${prefix}/FETCH_USER_ENTITLEMENTS`;
export const FETCH_USER_ENTITLEMENTS_SUCCESS = `${prefix}/FETCH_USER_ENTITLEMENTS_SUCCESS`;
export const FETCH_USER_ENTITLEMENTS_FAILURE = `${prefix}/FETCH_USER_ENTITLEMENTS_FAILURE`;

export const SAVE_FEATURE_FLAGS = `${prefix}/SAVE_FEATURE_FLAGS`;

export const FEATURE_ENTITLEMENTS = {
  VIEW_ASSESS_SELF_ORGANIZATION_ORDERS: 'VIEW_ASSESS_SELF_ORGANIZATION_ORDERS',
  VIEW_ASSESS_ALL_ORGANIZATION_ORDERS: 'VIEW_ASSESS_ALL_ORGANIZATION_ORDERS',
  VIEW_DECISIONS: 'VIEW_ASSESS_DECISIONS',
  MANAGE_DECISIONS: 'MANAGE_ASSESS_DECISIONS',
  VIEW_NOTES: 'VIEW_ASSESS_NOTES',
  MANAGE_NOTES: 'MANAGE_ASSESS_NOTES',
  VIEW_REPORTS: 'VIEW_ASSESS_REPORTS',
  MANAGE_REPORTS: 'MANAGE_ASSESS_REPORTS',
  MANAGE_TAGS: 'MANAGE_ASSESS_TAGS',
  MANAGE_QC_DECISIONS: 'MANAGE_ASSESS_QC_DECISIONS',
  VIEW_ASSESS_MANUAL_QC_INFO: 'VIEW_ASSESS_MANUAL_QC_INFO',
  MANAGE_MANUAL_AT_TIE_POINTS: 'MANAGE_ASSESS_MANUAL_AT_TIE_POINTS',
  GENERATE_REPORTS_INTERNAL: 'GENERATE_ASSESS_REPORTS_INTERNAL',
  EXPORT_IMAGERY_INTERNAL: 'EXPORT_ASSESS_IMAGERY_INTERNAL',
  MANAGE_MANUAL_AT_VIA_OPS_PRIME: 'MANAGE_MANUAL_AT_VIA_OPS_PRIME',
  MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME: 'MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME',
  MANAGE_PENDING_QC_VIA_OPS_PRIME: 'MANAGE_PENDING_QC_VIA_OPS_PRIME',
  VIEW_MANUAL_AT_VIA_OPS_PRIME: 'VIEW_MANUAL_AT_VIA_OPS_PRIME',
  MOVE_ASSESS_ORDER_BACK_TO_READY_FOR_REVIEW: 'MOVE_ASSESS_ORDER_BACK_TO_READY_FOR_REVIEW',
  ASSESS_REOPEN_CLOSED_CLAIM: 'ASSESS_REOPEN_CLOSED_CLAIM',
  ASSIGN_ASSESS_ORDER: 'ASSIGN_ASSESS_ORDER',
  VIEW_SELF_JOBS: 'VIEW_SELF_JOBS',
  DUPLICATE_ASSESS_ORDER: 'DUPLICATE_ASSESS_ORDER',
  DEMO_MANAGER: 'MOVE_ASSESS_ORDER_BACK_TO_READY_FOR_REVIEW',
};

export const RESCHEDULE_DRONE_INSPECTION_FLAG = 'assessRescheduleDroneinspection';
export const ENABLE_FACET_RAW_IMAGES = 'showRawImages';
export const GROUND_ACCESSORY = 'groundAccessory';
export const ANNOTATION_DESC = 'assessAnnotationDescription';
export const ANOMALY_DESC = 'assessAnomalyDescription';
export const QC_ENHANCEMENT = 'qcEnhancement';
export const ASSESS_SOFT_METALS_IN_REPORT = 'assessSoftMetalsInReport';
export const DUPLICATE_MULTI_STRUCTURE_ORDER = 'duplicateMultiStructureOrder';
export const ASSESS_SKYDIO_IMAGERY_IN_REPORT = 'assessSkydioImageryInReport';
export const GENERATE_ROOF_REPORT_FLAG = 'assessOrdermeasurementaftercapture';
export const REMEASURE_WRONG_HOUSE_FLAG = 'assessRejectremeasureSkydiocoordinates';
export const ASSESS_IWH_ENTITLEMENTS = 'assessIwhEntitlements';
export const ASSESS_RAW_IMAGERY_ENTITLEMENTS = 'assessRawimageryWorkflow';
export const ASSESS_MARKETING_PRODUCTNAMES = 'assessMarketingProductnames';
export const ASSESS_VIEW_SCHEDULING_DETAILS_ORDER_LISTING = 'assessViewschedulingdetailsOrderlisting';
export const ASSESS_MEASUREMENT_FEE_PRODUCT = 'assessMeasurementFeeProduct';
export const ASSESS_D2M_MULTISTRUCTURE = 'assessD2MMultistructure';
export const ASSESS_VIEW_MULTISTRUCTURE = 'assessViewMultistructure';
export const ASSESS_DISTRO = 'assessDistroOrder';
export const ASSESS_MANUAL_AT_EXPLORE_OBLIQUES = 'assessManualatExploreobliques';
export const ASSESS_PHOTO_VIEWER = 'assessPhotoViewer';
export const ASSESS_DOWNLOAD_RAW_IMAGERY = 'assessDownloadRawimagery';
export const ASSESS_PHOTO_VIEWER_MS = 'assessPhotoviewerMultistructure';
export const ASSESS_INTERNAL_REFLY = 'assessInternalRefly';
export const ASSESS_DIGITAL_DELIVERY = 'assessDigitalDelivery';
export const ASSESS_DOL_UPDATE = 'assessDolUpdate';
export const ASSESS_GROUND_PHOTO_GALLERY = 'assessGroundPhotogallery';
export const ASSESS_RESCHEDULE_SCHEDULER = 'assessRescheduleScheduler';
