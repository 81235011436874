import React, {
  createRef, useRef, useState, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EVBox,
  EVInputLabel,
  EVSelect,
  EVMenuItem,
  EVExpandMore,
  EVTypography,
  EVCircularProgress,
} from '@eagleview/ev-comp-library';
import { useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import useStyles from './PhotosSidePanel.styles';
import * as action from '../Photos.actions';
import PhotosThumbnailGrid from '../photos-thumbnails-grid';
import { GET_GROUND_THUMBNAILS, FIX_HEIGHT, GET_ALL_TAGS_FROM_S3 } from '../photos.constant';

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = (ele) => {
  setTimeout(() => {
    ele.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, 0);
};

const SidePanelGallery = () => {
  // utility hooks
  const { id: orderId } = useParams();
  const { t } = useTranslation();

  const chipRef = useRef(null);
  const chipHeight = get(chipRef, 'current', {});
  let chipMaxHeight = 0;
  if (chipHeight && typeof chipHeight.getBoundingClientRect === 'function') {
    const chipMax = chipHeight.getBoundingClientRect();
    chipMaxHeight = get(chipMax, 'height', 0);
  }

  const styles = useStyles({
    totalHeight: FIX_HEIGHT + chipMaxHeight,
  });

  const scrollRef = useRef(null);
  const headerRef = useRef(null);
  const elementsRef = useRef([]);

  // actions
  const dispatch = useDispatch();
  const fetchAllTags = (payload) => dispatch(action.fetchAllTagsFromS3Action(payload));
  const fetchGroundThumbnails = (payload) => dispatch(action.fetchGroundImageThumbnailsAction(payload));
  const setSelectedImageName = (payload) => dispatch(action.setSelectedGalleryImageNameAction(payload));

  // local state
  const [selectValue, setSelectValue] = useState(null);

  // selectors
  const selectedStructureId = useSelector((state) => get(state, 'photosReducer.selectedStructureId', ''));
  const allTags = useSelector((state) => get(state, 'photosReducer.photosUITags', []));
  const groundThumbnails = useSelector((state) => get(state, 'photosReducer.groundThumbnails', []));
  const loading = useSelector((state) => get(state, 'photosReducer.loading', false));
  const selectedImageName = useSelector((state) => get(state, 'photosReducer.selectedImageNameGalleryTab', ''));
  const apiSuccess = useSelector((state) => get(state, 'photosReducer.apiSuccess', false));

  useEffect(() => {
    fetchAllTags({
      orderId,
      structureId: selectedStructureId,
    });
  }, [selectedStructureId]);

  useEffect(() => {
    if (!isEmpty(allTags)) {
      setSelectValue(allTags[0].tag_id);
      elementsRef.current = allTags.map(() => createRef());
      fetchGroundThumbnails({
        orderId,
        structureId: selectedStructureId,
        groundImageTags: allTags,
      });
    }
  }, [allTags]);

  useEffect(() => {
    if (!isEmpty(groundThumbnails)) {
      setSelectedImageName(groundThumbnails[0].data.images[0].imageName);
    }
  }, [groundThumbnails]);

  const handleOnItemClicked = (image) => {
    setSelectedImageName(image.imageName);
  };

  const handleChange = (event) => {
    setSelectValue(event.target.value);
    const scrollToDiv = allTags.findIndex((tag) => tag.tag_id === event.target.value);
    scrollTo(elementsRef.current[scrollToDiv].current);
  };

  const scrollCheck = () => {
    const { height: headerHeight } = getDimensions(headerRef.current);
    const scrollPosition = scrollRef.current.scrollTop + headerHeight + elementsRef.current[0].current.offsetTop;
    if (scrollRef.current.clientHeight + scrollRef.current.scrollTop >= scrollRef.current.scrollHeight) {
      setSelectValue(allTags[allTags.length - 1].tag_id);
    } else {
      const selectedIndex = elementsRef.current.findIndex((ref) => {
        const ele = ref.current;
        let offsetBottom; let offsetTop;
        if (ele) {
          ({ offsetBottom, offsetTop } = getDimensions(ele));
        }
        return scrollPosition > offsetTop && scrollPosition < offsetBottom;
      });
      if (allTags[selectedIndex]) setSelectValue(allTags[selectedIndex].tag_id);
    }
  };

  return (
    <>
      { !isEmpty(allTags) && (
        <>
          <EVBox id="galleryList">
            <EVBox className={styles.stickyHeader}>
              <EVBox ref={headerRef} className={styles.filtersBox}>
                <EVBox className={styles.filterButtonContainer}>
                  <EVBox>
                    <EVInputLabel className={styles.categories}>Categories</EVInputLabel>
                    <EVSelect
                      value={selectValue}
                      size="small"
                      className={styles.select}
                      onChange={handleChange}
                      disableUnderline
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={EVExpandMore}
                      inputProps={{
                        classes: {
                          root: styles.statusSelect,
                          icon: styles.iconStatus,
                        },
                      }}
                    >
                      {allTags.length > 0 && (allTags.map((selectListItem) => (
                        <EVMenuItem key={selectListItem.tag_id} className={styles.menuItem} value={selectListItem.tag_id}>
                          {selectListItem.tag_name}
                        </EVMenuItem>
                      )))}
                    </EVSelect>
                  </EVBox>
                </EVBox>
              </EVBox>
            </EVBox>
            <>
              {!loading[GET_ALL_TAGS_FROM_S3] && apiSuccess[GET_ALL_TAGS_FROM_S3] && isEmpty(allTags)
                && (
                <EVBox
                  width="100%"
                  height="392px"
                  color="common.white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <EVTypography className={styles.categoryComponentTitle}>{t('photos-ui.tagsNotFound')}</EVTypography>
                </EVBox>
                )}
              {loading[GET_GROUND_THUMBNAILS] ? (
                <EVBox
                  width="100%"
                  height="392px"
                  color="common.white"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <EVCircularProgress className={styles.circularProgressBar} />
                </EVBox>
              )
                : (
                  <EVBox ref={scrollRef} className={styles.scrollableDiv} onScroll={() => { scrollCheck(); }}>
                    {allTags.map((categoryTag, index) => {
                      const filteredThumbnails = groundThumbnails.find((thumbnail) => thumbnail.tag === categoryTag.tag_id);
                      const images = get(filteredThumbnails, 'data.images', []);
                      return (
                        <EVBox ref={elementsRef.current[index]} className={styles.categoryComponentBox}>
                          <EVTypography className={styles.categoryComponentTitle}>{categoryTag.tag_name}</EVTypography>
                          <PhotosThumbnailGrid
                            images={images}
                            onItemClicked={(image) => handleOnItemClicked(image)}
                            selectedGridItem={selectedImageName}
                          />
                        </EVBox>
                      );
                    })}
                  </EVBox>
                )}
            </>
          </EVBox>
        </>
      )}
    </>
  );
};

export default SidePanelGallery;
