import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles((theme) => ({
  main: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  mapContainer: {
    width: (props) => `calc(100% - ${props.sidePanelWidth})`,
  },
  wrapper: {
    width: (props) => `calc(100% - ${props.sidePanelWidth} - 280px)`,
    height: `calc(100vh - ${theme.evSpacing['ev-spacing--32']})`,
    position: 'relative',
    marginLeft: (props) => props.sidePanelWidth,
    marginRight: '280px',
    backgroundColor: theme.palette.common.black,
    '& canvas': {
      outline: 'none',
    },
  },
  galleryWrapper: {
    width: (props) => `calc(100% - ${props.sidePanelWidth})`,
    height: `calc(100vh - ${theme.evSpacing['ev-spacing--32']})`,
    position: 'absolute',
    marginLeft: (props) => props.sidePanelWidth,
    backgroundColor: theme.palette.common.black,
    '& canvas': {
      outline: 'none',
    },
  },
  buttonsContainer: {
    position: 'absolute',
    zIndex: 1,
    left: '35%',
    top: '2%',
  },
  zoomToolBar: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    flexDirection: 'row-reverse',
    paddingRight: theme.spacing(2),
    bottom: `calc(${theme.evSpacing['ev-spacing--32']} + 32px)`,
  },
  orderToolbar: {
    position: 'absolute',
    zIndex: 1,
    right: theme.spacing(2),
    top: `calc(${theme.evSpacing['ev-spacing--4']} + 32px)`,
  },
  statusBar: {
    paddingRight: '140px',
    height: theme.evSpacing['ev-spacing--32'],
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: (props) => `calc(100% - ${props.sidePanelWidth})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.evPalette.salt[100],
    color: theme.evPalette.charcoal[600],
  },
  imageNameStyle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.evPalette.charcoal[600],
    fontSize: '14px',
    paddingLeft: theme.evSpacing['ev-spacing--16'],
  },
  sidepanel: {
    border: 'none',
  },
  overViewCapturepanel: {
    height: `calc(100% - ${theme.evSpacing['ev-spacing--32']})`,
  },
  headerText: {
    color: theme.evPalette.charcoal[700],
    fontSize: theme.typography.pxToRem(18),
    fontWeight: '700',
    lineHeight: theme.typography.pxToRem(18),
  },
  thumbnailGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  thumbnailItem: {
    boxSizing: 'borderBox',
  },
  thumbnailItemImg: {
    width: '80px',
    height: '80px',
    display: 'block',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  boxHeader: {
    width: '100%',
    display: 'flex',
    paddingLeft: '10px',
    paddingTop: '16px',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
  },
  cardContent: {
    overflowY: 'scroll',
    height: `calc(100vh - ${theme.evSpacing['ev-spacing--32']} - 74px)`,
  },
  overViewContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarPanel: {
    position: 'relative',
    left: theme.spacing(26.5),
    top: theme.spacing(1),
  },
  toolbarIconButton: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  compassStyle: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: '170px',
  },
}));
