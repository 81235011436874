import axios from 'axios';
import {
  ASSESS_ORDER_MANAGER_V2_API_ENDPOINT,
  ASSESS_SERVICE_API_ENDPOINT,
  ASSESS_PHOTOGRAMMETRY_UTIL_API_ENDPOINT,
} from 'constants.js';
import { isEmpty } from 'lodash';
import * as util from 'utils/utils';

export const fetchRoofImageApi = async (payload) => {
  const {
    orderId, imageName, structureId, imageType,
  } = payload;
  const queryParams = { imageType };
  try {
    const { data } = await axios.get(
      `${ASSESS_ORDER_MANAGER_V2_API_ENDPOINT}/manualat/orders/${orderId}/structures/`
      + `${structureId}/download-overview/image/${imageName}`,
      { responseType: 'blob', params: queryParams },
    );
    return { ...payload, data };
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchOrderDetailsApi = async (payload) => {
  try {
    const { orderId } = payload;
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const getThumbnailsApi = async (params) => {
  try {
    const {
      orderId, structureId, imageType, facetOverviewImageName,
    } = params;
    const queryParams = { imageType, facetOverviewImageName };
    if (!isEmpty(params.groundImageTag)) {
      queryParams.groundImageTag = params.groundImageTag;
    }
    const { data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/thumbnail`,
      {
        params: queryParams,
      },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const getPhotoAssets = async (params) => {
  try {
    const { orderId, structureId } = params;
    const queryParams = {};
    if (!isEmpty(params.imageName)) {
      queryParams.imageName = params.imageName;
    }
    const { data } = await axios.get(
      `${ASSESS_PHOTOGRAMMETRY_UTIL_API_ENDPOINT}/photoAssets/${orderId}/${structureId}/roof`,
      {
        params: queryParams,
      },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function createZip(zipDetails) {
  try {
    const { data } = await axios.post(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${zipDetails.orderId}/createzip`,
      zipDetails,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const checkZipStatusApi = async (params) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/zipstatus`, { params },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const getAllTagsFromS3Api = async (params) => {
  try {
    const { orderId, structureId } = params;
    const { data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images/tags`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
