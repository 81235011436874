import { logger, disableAutoFit } from 'utils/utils';

import {
  mercatorXfromLng,
  mercatorYfromLat,
  lngFromMercatorX,
} from 'layout/adjuster/Adjuster.utils';

import { tagNameMap } from 'layout/photos/photos.constant';

const sortTags = (tags) => tags.sort((a, b) => {
  const orderA = Object.keys(tagNameMap).indexOf(a.tag_id);
  const orderB = Object.keys(tagNameMap).indexOf(b.tag_id);
  return orderA - orderB;
});

export const getPhotosUITags = (tags) => {
  const roofTags = tags.roof
    .filter((tag) => tag.tag_name !== 'facet_scan' && tag.tag_name !== 'facet_overview')
    .map((tag) => ({
      tag_id: tag.tag_name,
      tag_name: tagNameMap[tag.tag_name] || tag.tag_name,
      tag_type: 'roof',
    }));

  const groundTags = tags.ground
    .map((tag) => ({
      tag_id: tag.tag_name,
      tag_name: tagNameMap[tag.tag_name] || tag.tag_name,
      tag_type: 'ground',
    }));

  const combinedTags = [...roofTags, ...groundTags];
  const sortedTags = sortTags(combinedTags);

  return sortedTags;
};

export const getBounds = (coordinates, viewportWidth, viewportHeight) => {
  let maxRoofLat;
  let minRoofLat;
  let minRoofLon;
  let maxRoofLon;
  const bufferDistanceH = 0;
  const bufferDistanceV = 0;

  for (let i = 0; i < coordinates.length; i += 1) {
    const coord = coordinates[i];
    const lon = coord[0];
    const lat = coord[1];

    if (maxRoofLat === undefined || lat > maxRoofLat) {
      maxRoofLat = lat;
    }

    if (minRoofLat === undefined || lat < minRoofLat) {
      minRoofLat = lat;
    }

    if (minRoofLon === undefined || lon < minRoofLon) {
      minRoofLon = lon;
    }

    if (maxRoofLon === undefined || lon > maxRoofLon) {
      maxRoofLon = lon;
    }
  }

  // ensuring that the bounding box maintains the same aspect ratio as the viewport,
  // which can be useful displaying an image in a way that fits nicely within the viewport.
  if (!disableAutoFit) {
    // calculate the bound coordinate in mercator projection.
    const minRoofX = mercatorXfromLng(minRoofLon);
    const minRoofY = mercatorYfromLat(minRoofLat);
    const maxRoofX = mercatorXfromLng(maxRoofLon);
    const maxRoofY = mercatorYfromLat(maxRoofLat);

    // calculate the x-coordinates of the bounding box in Mercator coordinates
    const minBoundX = mercatorXfromLng(minRoofLon - bufferDistanceH);
    const maxBoundX = mercatorXfromLng(maxRoofLon + bufferDistanceH);

    // aspect ratio of the viewport, which is the ratio of its width to its height.
    const viewportAspectRatio = viewportWidth / viewportHeight;

    // width and height of the bounding box in Mercator coordinates
    const boundWidth = Math.abs(maxBoundX - minBoundX);

    // calculate what the width of the bounding box would be if it were to maintain the same aspect ratio as the viewport
    const boundHeightByAspectRatio = boundWidth / viewportAspectRatio;
    const roofHeight = Math.abs(maxRoofY - minRoofY);

    logger('debug', `Roof Height to Map viewport height Ratio : ${roofHeight / boundHeightByAspectRatio}`);

    // If this ratio is greater than a certain value (0.5),
    // it adjusts the minimum and maximum longitude values to increase the width of the bounding box,
    // maintaining the aspect ratio. This is done by calculating the width of the bounding box that would
    // maintain the aspect ratio, subtracting the actual width of the bounding box,
    // and then subtracting and adding half of this difference from the minimum and maximum longitude values, respectively.

    if (roofHeight / boundHeightByAspectRatio > 0.5) {
      const roofWidthByAspectRatio = roofHeight * viewportAspectRatio;
      const roofWidth = Math.abs(maxRoofX - minRoofX);
      const diff = roofWidthByAspectRatio - roofWidth;
      minRoofLon = lngFromMercatorX(minRoofX - diff / 2);
      maxRoofLon = lngFromMercatorX(maxRoofX + diff / 2);
    }
  }

  return {
    ul: {
      lat: maxRoofLat + bufferDistanceV,
      lon: minRoofLon - bufferDistanceH,
    },
    ur: {
      lat: maxRoofLat + bufferDistanceV,
      lon: maxRoofLon + bufferDistanceH,
    },
    br: {
      lat: minRoofLat - bufferDistanceV,
      lon: maxRoofLon + bufferDistanceH,
    },
    bl: {
      lat: minRoofLat - bufferDistanceV,
      lon: minRoofLon - bufferDistanceH,
    },
  };
};
