const prefix = 'homeReducer';

export const FETCH_CURRENT_CLAIMS = `${prefix}/FETCH_CURRENT_CLAIMS`;
export const FETCH_CURRENT_CLAIMS_SUCCESS = `${prefix}/FETCH_CURRENT_CLAIMS_SUCCESS`;
export const FETCH_CURRENT_CLAIMS_FAILURE = `${prefix}/FETCH_CURRENT_CLAIMS_FAILURE`;

export const FETCH_PAST_CLAIMS = `${prefix}/FETCH_PAST_CLAIMS`;
export const FETCH_PAST_CLAIMS_SUCCESS = `${prefix}/FETCH_PAST_CLAIMS_SUCCESS`;
export const FETCH_PAST_CLAIMS_FAILURE = `${prefix}/FETCH_PAST_CLAIMS_FAILURE`;

export const FETCH_ADJUSTERS = `${prefix}/FETCH_ADJUSTERS`;
export const FETCH_ADJUSTERS_SUCCESS = `${prefix}/FETCH_ADJUSTERS_SUCCESS`;
export const FETCH_ADJUSTERS_FAILURE = `${prefix}/FETCH_ADJUSTERS_FAILURE`;

export const ASSIGN_ADJUSTER = `${prefix}/ASSIGN_ADJUSTER`;
export const ASSIGN_ADJUSTER_SUCCESS = `${prefix}/ASSIGN_ADJUSTER_SUCCESS`;
export const ASSIGN_ADJUSTER_FAILURE = `${prefix}/ASSIGN_ADJUSTER_FAILURE`;

export const CLOSE_ERROR_TOAST = `${prefix}/CLOSE_ERROR_TOAST`;

export const SET_TAB = `${prefix}/SET_TAB`;

export const SET_SAVED_TAB = `${prefix}/SET_SAVED_TAB`;

export const SET_SEARCH_QUERY = `${prefix}/SET_SEARCH_QUERY`;

export const SET_CURRENT_USER = `${prefix}/SET_CURRENT_USER`;

export const SET_CHECKED_ASSIGNMENT_FILTER_OPTIONS = `${prefix}/SET_CHECKED_ASSIGNMENT_FILTER_OPTIONS`;

export const SET_SHOW_ASSIGNMENT_PANEL = `${prefix}/SET_SHOW_ASSIGNMENT_PANEL`;

export const SET_CLAIM_UNDER_ASSIGNMENT = `${prefix}/SET_CLAIM_UNDER_ASSIGNMENT`;

export const SET_SHOW_TRACK_PANEL = `${prefix}/SET_SHOW_TRACK_PANEL`;

export const SET_CLAIM_BEING_TRACKED = `${prefix}/SET_CLAIM_BEING_TRACKED`;

export const GET_APPOINTMENT_DETAILS = `${prefix}/GET_APPOINTMENT_DETAILS`;
export const GET_APPOINTMENT_DETAILS_SUCCESS = `${prefix}/GET_APPOINTMENT_DETAILS_SUCCESS`;
export const GET_APPOINTMENT_DETAILS_FAILURE = `${prefix}/GET_APPOINTMENT_DETAILS_FAILURE`;
export const CLEAR_APPOINTMENT_DETAILS = `${prefix}/CLEAR_APPOINTMENT_DETAILS`;

export const RESCHEDULE_APPOINTMENT = `${prefix}/RESCHEDULE_APPOINTMENT`;
export const RESCHEDULE_APPOINTMENT_SUCCESS = `${prefix}/RESCHEDULE_APPOINTMENT_SUCCESS`;
export const RESCHEDULE_APPOINTMENT_FAILURE = `${prefix}/RESCHEDULE_APPOINTMENT_FAILURE`;
export const CLEAR_RESCHEDULE_APPOINTMENT_RESPONSE = `${prefix}/CLEAR_RESCHEDULE_APPOINTMENT_RESPONSE`;

export const claimStatus = {
  ORDERED: 'Ordered',
  SCHEDULED: 'Scheduled',
  INSPECTIONCOMPLETE: 'InspectionComplete',
  PROCESSING: 'Processing',
  READYFORREVIEW: 'ReadyForReview',
  COMPLETED: 'Completed',
  ORDERCLOSED: 'OrderClosed',
};

export const TABS = {
  QUEUE: 0,
  ORDERED: 1,
  ARCHIVE: 2,
  SEARCH: 3,
};

export const TRANSLATION_MAPPING = {
  Ordered: {
    status: 'home.status.ordered',
    logStatus: 'home.status.ordered',
    additionalInfo: 'home.additionalInfo.orderedTime',
  },
  Scheduled: {
    status: 'home.status.scheduled',
    logStatus: 'home.status.scheduled',
    additionalInfo: 'home.additionalInfo.appointmentTime',
  },
  InspectionComplete: {
    status: 'home.status.inspectionComplete',
    logStatus: 'home.status.inspectionComplete',
    additionalInfo: 'home.additionalInfo.completedTime',
  },
  Processing: {
    status: 'home.status.processing',
    logStatus: 'home.status.processingStarted',
    additionalInfo: 'home.additionalInfo.processingStarted',
  },
  ReadyForReview: {
    status: 'home.status.readyForReview',
    logStatus: 'home.status.readyForReview',
    additionalInfo: 'home.additionalInfo.processingComplete',
  },
  Completed: {
    status: 'home.status.completed',
    logStatus: 'home.status.completed',
    additionalInfo: 'home.additionalInfo.completedTime',
  },
  OrderClosed: {
    status: 'home.status.orderClosed',
    logStatus: 'home.status.orderClosed',
    additionalInfo: 'home.additionalInfo.closedTime',
  },
};

export const STEPPER_STEPS = [
  claimStatus.ORDERED,
  claimStatus.SCHEDULED,
  claimStatus.INSPECTIONCOMPLETE,
  claimStatus.PROCESSING,
  claimStatus.READYFORREVIEW,
];

export const STATUS_STEPPER_STEPS_MAPPING = {
  Ordered: 'home.stepperSteps.ordered',
  Scheduled: 'home.stepperSteps.scheduled',
  InspectionComplete: 'home.stepperSteps.inspection',
  Processing: 'home.stepperSteps.processed',
  ReadyForReview: 'home.stepperSteps.ready',
};

export const MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME = {
  fullScan: 'claim.marketing.productName.fullScan',
  quickCapture: 'claim.marketing.productName.quickCapture',
  assessMeasure: 'claim.marketing.productName.assessMeasure',
};

export const COLLAPSED_HISTORY_COUNT = 2;

export const CLOSED_OR_COMPLETED_ORDER_STATUS = [claimStatus.COMPLETED, claimStatus.ORDERCLOSED];

export const ASSIGNMENT_FILTER_OPTIONS = {
  ASSIGNED_TO_ME: 'assignedToMe',
  UNASSIGNED: 'unassigned',
};

export const CURRENT_USER_EXTENSION = '(Myself)';

export const LISTING_DATE_TIME_FORMAT = 'MMMM D, h:mma';

export const TRACKING_DATE_TIME_FORMAT = 'MMMM D, YYYY, h:mma';

export const ESTIMATED_DATE_FORMAT = 'dddd, MMMM D';

export const ESTIMATED_DELIVERY_MAP = {
  NOT_AVAILABLE: 'home.notAvailable',
  NOT_SCHEDULED: 'home.notScheduled',
};

export const READY_FOR_IMG_CAPTURE_STATUS = 'ReadyForOnSiteImageCapture';

export const HOME_OWNER_UNAVAILABLE_OPT = 'homeOwnerUnavailable';
export const WEATHER_OPT = 'weather';
export const WET_ROOF_OPT = 'wetRoof';
export const OTHERS_OPT = 'other';
export const MAX_REJECT_REASON_LENGTH = 50;
export const EMPTY_STRING = '';

export const RESCHEDULE_METHOD_HOMEOWNER = 'homeOwner';
export const RESCHEDULE_METHOD_SCHEDULER_PORTAL = 'schedulerPortal';

export const RESCHEDULE_REASONS = {
  [HOME_OWNER_UNAVAILABLE_OPT]: 'home.rescheduleReasonUnavailable',
  [WEATHER_OPT]: 'home.rescheduleReasonWeather',
  [WET_ROOF_OPT]: 'home.rescheduleReasonWetRoof',
  [OTHERS_OPT]: 'home.rescheduleReasonOther',
};

export const RESCHEDULE_METHODS = {
  [RESCHEDULE_METHOD_HOMEOWNER]: 'home.rescheduleHomeOwner',
  [RESCHEDULE_METHOD_SCHEDULER_PORTAL]: 'home.rescheduleSchedulerPortal',
};

export const SCHEDULER_PORTAL_BASE_ADDRESS = '/scheduler';
