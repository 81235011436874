import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  EVBox,
  EVCircularProgress,
} from '@eagleview/ev-comp-library';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import useStyles from './PhotosThumbnailsGrid.styles';
import { FETCH_THUMBNAILS } from '../photos.constant';

const PhotosThumbnailsGrid = forwardRef(({
  images, onItemClicked, onItemHovered, hoveredGridItem, selectedGridItem,
}, ref) => {
  const styles = useStyles();
  const [selectedImage, setSelectedImage] = useState(selectedGridItem);
  const [isLoading, setLoader] = useState(true);

  const loading = useSelector((state) => get(state, 'photosReducer.loading', {}));

  const handleItemClicked = (index) => {
    setSelectedImage(index);
    onItemClicked(images[index]);
  };

  const handleItemHovered = (index, isMouseOutEvent = false) => {
    const idx = isMouseOutEvent ? images.findIndex((image) => (image.imageName === selectedGridItem)) : index;
    setSelectedImage(idx);
    onItemHovered(images[idx]);
  };

  useEffect(() => {
    let imageIndexToBeHighlighted = -1;
    const imageToBeHighlighted = isEmpty(hoveredGridItem) ? selectedGridItem : hoveredGridItem;
    if (!isEmpty(images)) {
      imageIndexToBeHighlighted = images.findIndex((image) => (image.imageName === imageToBeHighlighted));
      if (imageIndexToBeHighlighted !== -1) {
        setSelectedImage(imageIndexToBeHighlighted);
      } else {
        setSelectedImage(null);
      }
    }
  }, [hoveredGridItem, selectedGridItem]);

  useEffect(() => {
    setLoader(loading[FETCH_THUMBNAILS]);
  }, [loading[FETCH_THUMBNAILS]]);

  return (
    <EVBox ref={ref} height="inherit" className={styles.thumbnailsWrapper}>
      {
        isLoading ? (
          <EVBox display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            <EVCircularProgress />
          </EVBox>
        ) : (
          <EVBox className={styles.thumbnailGrid}>
            {!isEmpty(images) && images.map((image, index) => (
              <EVBox
                data-testid="thumbnail-item"
                className={`${styles.thumbnailItem} ${selectedImage === index ? styles.thumbnailItemSelected : ''}`}
                key={image.imageName}
                onClick={() => handleItemClicked(index)}
                onMouseOver={() => handleItemHovered(index)}
                onMouseOut={() => handleItemHovered(-1, true)}
              >
                <img className={styles.thumbnailItemImg} src={`data:image/jpg;base64, ${image.imageBytes}`} alt={`Thumbnail ${index + 1}`} />
              </EVBox>
            ))}
          </EVBox>
        )
      }
    </EVBox>
  );
});

PhotosThumbnailsGrid.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  onItemClicked: PropTypes.func,
  onItemHovered: PropTypes.func,
  hoveredGridItem: PropTypes.string,
  selectedGridItem: PropTypes.string,
};

PhotosThumbnailsGrid.defaultProps = {
  images: [],
  onItemClicked: () => {},
  onItemHovered: () => {},
  hoveredGridItem: '',
  selectedGridItem: '',
};

export default PhotosThumbnailsGrid;
