import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import {
  LISTING_DATE_TIME_FORMAT, TRACKING_DATE_TIME_FORMAT, claimStatus, ESTIMATED_DATE_FORMAT, ESTIMATED_DELIVERY_MAP,
} from './Home.constants';

export default function (payload) {
  if (payload && payload.Orders) {
    return orderBy(
      payload.Orders,
      (order) => [
        moment.unix(get(order, 'DateCreated')).format('YYYY-MM-DD'),
        get(order, 'DateUpdated')
          ? moment.unix(get(order, 'DateUpdated')).format('YYYY-MM-DD')
          : '1970-01-01',
      ],
      ['desc', 'desc'],
    ).map((order) => {
      const assignedTo = get(order, 'AssignedTo');
      const status = get(order, 'Status', '');
      const updatedDate = get(order, 'DateUpdated', '');
      const createDate = get(order, 'DateCreated', '');
      const defaultOrderHistory = [{
        Status: status,
        ReachedAt: updatedDate || createDate,
      }];
      const appointmentHistory = get(order, 'AppointmentHistory', []) || [];
      const orderHistory = get(order, 'OrderHistory') || defaultOrderHistory;
      const statusChangeDate = get(orderHistory[0], 'ReachedAt', '');
      const appointmentDate = status === claimStatus.SCHEDULED
        && get(appointmentHistory.find((data) => get(orderHistory[0], 'ID') === data.ScheduleRef), 'TimeFrom', '');
      const estimatedDelivery = get(order, 'EstimatedDelivery', '');
      return {
        orderId: get(order, 'OrderID', ''),
        claimId: get(order, 'ReferenceID', ''),
        address: get(order, 'Address.StreetAddress1', ''),
        address2: [
          get(order, 'Address.City', false),
          get(order, 'Address.State', false),
          get(order, 'Address.Zip', false),
        ]
          .filter(Boolean)
          .join(' '),
        city: get(order, 'Address.City', ''),
        state: get(order, 'Address.State', ''),
        zipCode: get(order, 'Address.Zip', ''),
        createDate: createDate ? moment.unix(createDate).format('DD MMM YYYY') : false,
        updatedDate: updatedDate ? moment.unix(updatedDate).format('DD MMM YYYY') : false,
        claimOwner: assignedTo && assignedTo.firstName && assignedTo.lastName ? `${assignedTo.firstName} ${assignedTo.lastName}` : '',
        claimOwnerFirstName: get(assignedTo, 'firstName', ''),
        claimOwnerLastName: get(assignedTo, 'lastName', ''),
        claimOwnerEmailId: assignedTo && assignedTo.userName ? assignedTo.userName : '',
        riskShotImage: get(order, 'RiskShotImage', false),
        riskShotImageOrientation: get(order, 'RiskShotImageOrientation', 0),
        status,
        statusChangeDate: statusChangeDate ? moment.unix(statusChangeDate).format(LISTING_DATE_TIME_FORMAT) : '',
        orderHistory: orderHistory.map((history) => {
          const reachedAt = get(history, 'ReachedAt', '');
          const updatedHistory = {
            ...history,
            reachedAt: reachedAt ? moment.unix(reachedAt).format(TRACKING_DATE_TIME_FORMAT) : '',
          };
          if (history.Status === claimStatus.SCHEDULED) {
            const historyAppointmentDate = get(appointmentHistory.find((data) => history.ID === data.ScheduleRef), 'TimeFrom');
            return {
              ...updatedHistory,
              appointmentDate: historyAppointmentDate ? moment.unix(historyAppointmentDate).format(TRACKING_DATE_TIME_FORMAT) : '',
            };
          }
          return updatedHistory;
        }),
        appointmentHistory,
        legacySubStatus: get(order, 'LegacySubStatus', ''),
        imageryUploadComplete: get(order, 'ImageryUploadComplete', false),
        isWeFly: get(order, 'IsWeFly', false),
        isAssessLite: get(order, 'IsAssessLite', false),
        flightType: get(order, 'FlightType', ''),
        appointmentDate: appointmentDate ? moment.unix(appointmentDate).format(LISTING_DATE_TIME_FORMAT) : '',
        estimatedDeliveryDate: (estimatedDelivery && (Object.keys(ESTIMATED_DELIVERY_MAP).includes(estimatedDelivery)
          ? estimatedDelivery : moment.unix(estimatedDelivery).format(ESTIMATED_DATE_FORMAT)))
        || '',
        affiliatedOrganizationName: order.AffiliatedOrgsInfo ? order.AffiliatedOrgsInfo.map((org) => org.Name).join(', ') : 'N/A',
      };
    });
  }
  return [];
}

export const getClaimOwner = (claim) => {
  const assignedTo = get(claim, 'assignedTo');
  return {
    claimOwner: assignedTo && assignedTo.firstName && assignedTo.lastName ? `${assignedTo.firstName} ${assignedTo.lastName}` : '',
    claimOwnerFirstName: get(assignedTo, 'firstName', ''),
    claimOwnerLastName: get(assignedTo, 'lastName', ''),
    claimOwnerEmailId: assignedTo && assignedTo.userName ? assignedTo.userName : '',
  };
};
